<template>
  <div class="page">
    <section>
      <header>
        <img
          @click="$router.push({ path: '/' })"
          class="logo"
          src="@image/logo.png"
          alt="logo"
        />
        <span @click="$router.push({ path: '/' })" class="title">网医</span>
        <div class="goToLogin">
          <span>已有帐号，</span>
          <span
            class="login"
            @click="bus.$emit('method', { method: 'tabShowLogin', options: true })"
            >马上登录</span
          >
        </div>
      </header>
      <div class="form">
        <header>
          <span>忘记密码</span>
        </header>
        <section>
          <el-input
            v-model="phone"
            placeholder="请输入手机号"
            prefix-icon="el-icon-mobile-phone"
            autocomplete="off"
          >
          </el-input>
          <el-input
            autocomplete="off"
            v-model="code"
            class="code"
            placeholder="请输入验证码"
            prefix-icon="el-icon-message"
          >
            <template slot="append">
              <div v-if="isCode" @click="massCode">获取验证码</div>
              <div v-else>{{ time }}秒重新获取</div>
            </template>
          </el-input>
          <!-- <el-input v-model="password" placeholder="请输入密码（6-20位）" show-password></el-input> -->
          <el-tooltip
            class="item"
            effect="light"
            content="密码8-24位,至少包括字母,大小写,数字,特殊字符（!@#$%^&*_-）"
            placement="top-start"
          >
            <el-input
              placeholder="请输入密码（8-24位）"
              show-password
              prefix-icon="el-icon-lock"
              v-model="password"
              @blur="passwordVerify()"
              autocomplete="off"
            >
            </el-input>
          </el-tooltip>
          <el-input
            v-model="repassword"
            placeholder="请再次确认密码"
            show-password
            prefix-icon="el-icon-lock"
            autocomplete="off"
          >
          </el-input>
          <el-button @click="isForget_pas" round>确定</el-button>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      readAndAgree: false,
      phone: "",
      password: "",
      repassword: "",
      code: "",
      isCode: true,
      time: "60",
    };
  },
  methods: {
    // 发送短信
    async massCode() {
      if (!this.phone) {
        this.$message({ message: "请输入手机号", type: "error" });
        return;
      }
      this.time = 60;
      // let phone =  this.phone;
      let res = await this.$loginApi.massCode({
        type: 5,
        phone: this.phone,
        status: 1,
      });
      if (res.code == 200) {
        this.isCode = false;
        let isShow = setInterval(() => {
          this.time = this.time - 1;
          if (this.time <= 0) {
            this.isCode = true;
            clearTimeout(isShow);
          }
        }, 1000);
        this.$message({ message: "短信已发送", type: "success" });
      } else {
        this.$message({ message: res.message, type: "error" });
      }
    },
    // 忘记密码
    async isForget_pas() {
      let { phone, password, repassword, code } = this;
      if (!phone) {
        this.$message({ message: "请输入手机号", type: "error" });
        return;
      }
      if (!code) {
        this.$message({ message: "请输入验证码", type: "error" });
        return;
      }
      if (!password) {
        this.$message({ message: "请输入密码", type: "error" });
        return;
      }
      if (password.length < 6) {
        this.$message({ message: "请输入密码（6-20位）", type: "error" });
        return;
      }
      if (!repassword) {
        this.$message({ message: "请确认密码", type: "error" });
        return;
      }
      if (password != repassword) {
        this.$message({
          message: "两次密码输入不一致，请重新输入",
          type: "error",
        });
        this.password = "";
        this.repassword = "";
        return;
      }
      let { code: coid, data } = await this.$loginApi.forget_pas_code({
        phone,
        code,
      });
      if (coid == 200) {
        let res = await this.$loginApi.forget_pas({
          phone,
          password,
          repassword,
          code,
        });
        if (res.code == 200) {
          this.$message({ message: "恭喜你，密码修改成功", type: "success" });
          this.xin.goUrl("/");
        }
      }
    },

    // 密码校验
    passwordVerify() {
      let reg =
        /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*_-])[0-9a-zA-Z!@#$%^&*_-]{8,24}$/;
      if (!reg.test(this.password)) {
        this.$message.error("密码格式有误");
        this.password = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.page {
  background-color: #f4f4f4;
  > section {
    display: flex;
    flex-direction: column;
    > header {
      height: 176px;
      display: flex;
      align-items: center;
      > .logo {
        width: 56px;
        height: 56px;
        cursor: pointer;
      }
      > .title {
        font-size: 30px;
        margin-left: 10px;
        cursor: pointer;
      }
      > .goToLogin {
        margin-left: auto;
        display: flex;
        font-size: 24px;
        color: #333333;
        > .login {
          color: $--themeColor;
          cursor: pointer;
        }
      }
    }
    > .form {
      /deep/.el-input--prefix {
        color: #8f8f8f;
        background: #f2f2f2;
        border-radius: 44px;
        .el-input__inner {
          border: none;
          background: none;
        }
      }
      > header {
        height: 80px;
        background-color: $--themeColor;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        color: white;
      }
      > section {
        background-color: white;
        padding: 30px 0 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .el-input {
          width: 360px;
          margin-top: 20px;
          // margin: 0 auto;
          &:first-child {
            margin-top: 0;
          }
          ::v-deep .el-input-group__append {
            background: none;
            border: none;
            color: $--themeColor;
            cursor: pointer;
          }
          // ::v-deep .el-input__suffix {
          // 	// position: absolute;
          // 	// left: -57px;
          // 	// right: calc(100% + 32px);
          // 	// color: $--themeColor;
          // 	// font-size: 20px;
          // 	// display: none;
          // }
          ::v-deep .el-input__prefix {
            font-size: 16px;
          }
          // &.code {
          // 	::v-deep .el-input__suffix {
          // 		position: absolute;
          // 		left: calc(-57px + 110px);
          // 		right: calc(100% + 32px);
          // 		transition: 0s;
          // 	}
          // }
        }
        > .el-button {
          margin-top: 20px;
          width: 360px;
          height: 44px;
          border-radius: 44px;
          border: none;
          background-color: $--themeColor;
          color: white;
          font-size: 16px;
        }
        > .readAndAgree {
          margin: 20px auto 0;
        }
      }
    }
  }
}
</style>
